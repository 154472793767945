import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  BASE_STATE_DEFAULTS,
  BaseStateModel,
} from '../../interfaces/base-state-model.interface';
import { Overview } from '../interfaces/overview.interface';

export class LoadOverview {
  static readonly type = '[Overview] Load overview';
}

export interface OverviewStateModel extends BaseStateModel {
  overview: Overview;
}

@State<OverviewStateModel>({
  name: 'overview',
  defaults: {
    ...BASE_STATE_DEFAULTS,
    overview: {
      benefit: 0,
      workLifeBenefit: 0,
      compensation: 0,
      paidTimeOff: 0,
    },
  },
})
@Injectable()
export class OverviewState {
  private http = inject(HttpClient);

  @Selector()
  static loading(state: OverviewStateModel) {
    return state.loading;
  }

  @Selector()
  static loaded(state: OverviewStateModel) {
    return state.loaded;
  }

  @Selector()
  static error(state: OverviewStateModel) {
    return state.error;
  }

  @Action(LoadOverview)
  async loadOverview(ctx: StateContext<OverviewStateModel>) {}
}
